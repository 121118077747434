// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: MIT-0
import React from 'react';
import AnchorNavigation from '@cloudscape-design/components/anchor-navigation';
import ExpandableSection from '@cloudscape-design/components/expandable-section';
import Box from '@cloudscape-design/components/box';

function OnThisPageNavigation({ variant }: { variant: 'mobile' | 'side' }) {
    const anchorNavigation = (
        <AnchorNavigation
            anchors={[
                {
                    text: 'Who we are',
                    href: '#whoweare',
                    level: 1,
                },
                {
                    text: 'What we do',
                    href: '#whatwedo',
                    level: 1,
                },
                {
                    text: 'Our strategy',
                    href: '#strategy',
                    level: 1,
                },
                {
                    text: 'Our values',
                    href: '#ourvalues',
                    level: 1,
                },
                {
                    text: 'Our impact',
                    href: '#impact',
                    level: 1,
                },
                {
                    text: 'Our team',
                    href: '#team',
                    level: 1,
                }
            ]}
            ariaLabelledby="navigation-header"
        />
    );

    return variant === 'side' ? (
        <div className="on-this-page--side" data-testid="on-this-page">
            <Box variant="h2" margin={{ bottom: 'xxs' }}>
                <span id="navigation-header">On this page</span>
            </Box>
            {anchorNavigation}
        </div>
    ) : (
        <ExpandableSection variant="footer" headingTagOverride="h2" headerText="On this page">
            {anchorNavigation}
        </ExpandableSection>
    );
}

export { OnThisPageNavigation };