import logo from './logo.svg'
import './App.css'
import { DataGrid, GridRowsProp, GridColDef } from '@mui/x-data-grid';
import {  useEffect, useState } from "react";
import { CloudFormationClient, ListExportsCommand, ListExportsCommandOutput } from '@aws-sdk/client-cloudformation' // ES Modules import

import AWS from 'aws-sdk'
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

import {  QueryClient, QueryClientProvider } from '@tanstack/react-query'

import { Amplify } from 'aws-amplify';
import { SurveyTable } from './pages/Surveys'
import { TopNavigationComponent } from './components/TopNavigation'
import ContentLayout from '@cloudscape-design/components/content-layout';
import BreadcrumbGroup from '@cloudscape-design/components/breadcrumb-group';
import Box from '@cloudscape-design/components/box';
import Badge from '@cloudscape-design/components/badge';
import SpaceBetween from '@cloudscape-design/components/space-between';
import Link from '@cloudscape-design/components/link';
import Button from '@cloudscape-design/components/button';
import Container from '@cloudscape-design/components/container';
import Header from '@cloudscape-design/components/header';
import TextContent from '@cloudscape-design/components/text-content';
import Grid from "@cloudscape-design/components/grid";

import './styles/product-page.scss';

// Custom components
import { HeaderCustom } from './components/header';
import { OnThisPageNavigation } from './components/on-this-page';

import sdg04 from './images/E_SDG_Icons-04.jpg';
import sdg09 from './images/E_SDG_Icons-09.jpg';
import sdg12 from './images/E_SDG_Icons-12.jpg';
import sdg17 from './images/E_SDG_Icons-17.jpg';
import andreyTeam from './images/Andrey team photo.jpg';

const queryClient = new QueryClient();

Amplify.configure({
    // Auth: {
    //     Cognito: {
    //         //  Amazon Cognito User Pool ID
    //         userPoolId: import.meta.env
    //             .VITE_CONFIG_COGNITO_USERPOOL_ID,
    //         // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    //         userPoolClientId: import.meta.env
    //             .VITE_CONFIG_COGNITO_APPCLIENT_ID,
    //         // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
    //         identityPoolId: import.meta.env
    //             .VITE_CONFIG_COGNITO_IDENTITYPOOL_ID,
    //     }
    // },
    // Storage: {
    //     S3: {
    //         region: import.meta.env.VITE_REGION,
    //         bucket: import.meta.env.VITE_CONFIG_S3_KNOWLEDGE_BUCKET_NAME,
    //     },
    // },
    API: {
        GraphQL: {
            endpoint: 'https://yibmjqhsmbbx7jqhigxhbvd4ai.appsync-api.eu-west-3.amazonaws.com/graphql',
            region: 'eu-west-3',
            defaultAuthMode: 'apiKey',
            apiKey: 'da2-cdrgq24b3zairobmhrfqbtmp4m'
        }
    }
})

function App() {

    const [restApiURL, setRestApiURL] = useState<String | undefined>("");
    useEffect(() => {
        getExport()
    }, [])


    const getExport = async () => {

        const SESConfig = {
            apiVersion: "latest",
            credentials: {
                accessKeyId: 'AKIA4NESIDHEUHTDU422',
                secretAccessKey: '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'
            },
            region: 'eu-west-3'
        }
        AWS.config.update(SESConfig)

        const client = new CloudFormationClient(SESConfig);

        // const input = { // ListExportsRequest
        //     filters: { // ListExportsRequestFilters
        //         exportIDs: [ // ListExportsRequestFiltersExportIDs
        //             "RestapiOutput",
        //         ],
        //     },
        //     maxResults: 1
        // };
        const command = new ListExportsCommand();

        const response = await client.send(command);
        const url = response.Exports?.filter(exp => exp.Name?.includes("RestapiOutput"))[0].Value
        setRestApiURL(url)

        return response;
    };

    function ProductCard({
                             title,
                             vendor,
                             logo,
                             category,
                             description,
                             isNew = false,
                         }: {
        title: string;
        vendor: string;
        logo: string;
        category: string;
        description: string;
        isNew?: boolean;
    }) {
        return (
            <li className="product-cards-list-item" aria-label={title}>
                <Container>
                    <img src={logo} alt={`${title} logo`} width="50" height="50" />
                    <SpaceBetween direction="vertical" size="s">
                        <SpaceBetween direction="vertical" size="xxs">
                            <Box variant="h3">
                                <Link fontSize="inherit">{title}</Link>
                            </Box>
                            <Box variant="small">By {vendor}</Box>
                            <Box color="text-body-secondary">{category}</Box>
                            {isNew && <Badge color="green">New</Badge>}
                        </SpaceBetween>
                        <Box variant="p">{description}</Box>
                        <Button ariaLabel={`Shop now for ${title}`}>Shop now</Button>
                    </SpaceBetween>
                </Container>
            </li>
        );
    }

    function OurValues() {
        return (
            <section className="page-section" aria-label="Our values">
                <Header variant="h2">
                    <span id="ourvalues">Our values</span>
                </Header>
                <ul className="product-cards-list">
                    <ProductCard
                        title="Cloud Data Operating System"
                        vendor="Cloud Data"
                        logo="./resources/image-placeholder.png"
                        category="Professional services"
                        description="An operating system that is tailored for the cloud. This offering includes a free, full featured 30-day trial."
                        isNew={true}
                    />
                    <ProductCard
                        title="Cloud Data Deep Security"
                        vendor="Cloud Data"
                        logo="./resources/image-placeholder.png"
                        category="AMI | v20.0.833"
                        description="Security built for all your cloud services. Apply rules and policies to your services and make this as strict as necessary."
                    />
                </ul>
            </section>
        );
    }

    function WhoWeAre() {
        return (
            <section className="page-section" aria-label="Who we are">
                <Header variant="h2">
                    <span id="whoweare">Who we are</span>
                </Header>
                <SpaceBetween size="m">
                    <div>
                        <Box variant="p">
                            We are a group of professionals from Data Science and IT fields that are determined to drive integration
                            of sustainability principles into business solutions.
                        </Box>
                        <Box variant="p">
                            We specifically focus on AI aspect of this direction both to
                            transform conventional applications into a smarter ones which are usually more efficient and to make sure that the way AI is used is safe and responsible.

                        </Box>
                    </div>
                </SpaceBetween>
            </section>
        );
    }

    function WhatWeDo() {
        return (
            <section className="page-section" aria-label="What we do">
                <Header variant="h2">
                    <span id="whatwedo">What we do</span>
                </Header>
                <SpaceBetween size="m">
                    <div>
                        {/*<Header variant="h3">What we can help you with</Header>*/}
                        <TextContent>
                            <ul>
                                <li>We develop. If you are looking for a help to develop and integrate AI solutions for your services - look no further.
                                    Not only our team is strong in the Data Science field but also is educated on the topic of sustainability,
                                    so both development itself and the final products take into account social and environmental impacts and goals.
                                    That might be advantageous if your governance strategy requires production of the social and environmental values.
                                </li>
                                <li>
                                    We consult. We will be glad to share our expertise and provide actionable plan to transform your business to a more sustainable one.
                                    We can also help with finding new opportunities within your business model that are coming from sustainability aspect.
                                </li>
                                <li>We report. We help with sustainability reporting and compliance.</li>
                                <li>We research. We are partnering with relevant university departments and research groups and there is an opportunity for your business to contribute.</li>
                            </ul>
                        </TextContent>
                    </div>
                </SpaceBetween>
            </section>
        );
    }

    function Strategy() {
        return (
            <section className="page-section" aria-label="Our strategy">
                <Header variant="h2">
                    <span id="strategy">Our strategy</span>
                </Header>
                <SpaceBetween size="m">
                    <div>
                        <TextContent>
                            <ul>
                                <li>Real-time analytic alerts to detect anomalies across your products and services.
                                </li>
                                <li>
                                    Prepare data sets to increase visibility into areas of your organization to make
                                    business decisions.
                                </li>
                                <li>Build and manage large data sets to gain deeper insights and track trends.</li>
                                <li>Begin a 30 day free trial to get actionable insights today.</li>
                            </ul>
                        </TextContent>
                    </div>

                </SpaceBetween>
            </section>
        );
    }

    function Impact() {
        return (
            <section className="page-section" aria-label="Product overview">
                <Header variant="h2">
                    <span id="impact">SDGs</span>
                </Header>
                <SpaceBetween size="m">
                    <Box variant="p">
                        Here is a set of SDGs that we believe our strategy is most relevant to.
                        <br />
                        <Link href="https://sdgs.un.org/goals" variant="primary" external={true}>
                            Learn more about United Nations sustainable development goals
                        </Link>
                    </Box>
                    <Grid
                        gridDefinition={[{ colspan: 3 }, { colspan: 3 }, { colspan: 3 }, { colspan: 3 }]}
                    >
                        <img src={sdg04} alt="Industry, Innovation and Infrastructure" width="125"
                             height="125" />
                        <img src={sdg09} alt="Industry, Innovation and Infrastructure" width="125"
                             height="125" />
                        <img src={sdg12} alt="Industry, Innovation and Infrastructure" width="125"
                             height="125" />
                        <img src={sdg17} alt="Industry, Innovation and Infrastructure" width="125"
                             height="125" />
                    </Grid>

                </SpaceBetween>
            </section>
        );
    }

    function Team() {
        return (
            <section className="page-section" aria-label="Our team">
                <Header variant="h2">
                    <span id="team">Team</span>
                </Header>
                <SpaceBetween size="m">
                    <Grid
                        gridDefinition={[{ colspan: 3 }, { colspan: 3 }, { colspan: 3 }, { colspan: 3 }]}
                    >
                        <img src={andreyTeam} alt="Andrey Spiridonov, Founder and CEO" width="150" />

                    </Grid>

                </SpaceBetween>
            </section>
        );
    }


    return (
        <div className="App">
            <TopNavigationComponent />
           {/* <div> {restApiURL === "" ? "loading..." : restApiURL}</div>*/}
            <ContentLayout
                breadcrumbs={
                    <BreadcrumbGroup
                        items={[
                            { href: '/', text: 'Main' },
                            { href: '#/product', text: 'Sustainable AI mission' },
                        ]}
                        expandAriaLabel="Show path"
                        ariaLabel="Breadcrumbs"
                    />
                }
                headerVariant="high-contrast"
                header={<HeaderCustom />}
                defaultPadding={true}
                maxContentWidth={1040}
                disableOverlap={true}
            >
                <div className="product-page-content-grid">
                    <div className="on-this-page--mobile">
                        <OnThisPageNavigation variant="mobile" />

                    </div>

                    <aside aria-label="Side bar" className="product-page-aside">
                        <div className="product-page-aside-sticky">
                            <OnThisPageNavigation variant="side" />
                        </div>
                    </aside>

                    <main className="product-page-content">
                        <WhoWeAre />
                        <WhatWeDo />
                        <Strategy />
                        <Impact />
                        <Team />
                        <OurValues />
                    </main>

                    {/*<aside className="product-page-mobile" aria-label="Side bar">
                        User feedback
                    </aside>*/}
                </div>
            </ContentLayout>

            {/*<QueryClientProvider client={queryClient}>*/}
            {/*    <SurveyTable isLoading={false} />*/}
            {/*</QueryClientProvider>*/}
        </div>


    )
}

export default App
