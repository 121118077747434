// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: MIT-0
import React from 'react';
import Box from '@cloudscape-design/components/box';
import Button from '@cloudscape-design/components/button';
import Link from '@cloudscape-design/components/link';
import Grid from '@cloudscape-design/components/grid';
import SpaceBetween from '@cloudscape-design/components/space-between';

function HeaderCustom() {
    return (
        <Box data-testid="hero-header" padding={{ top: 'xs', bottom: 'l' }}>
            <Grid gridDefinition={[{ colspan: { default: 12, xs: 8, s: 9 } }, { colspan: { default: 12, xs: 4, s: 3 } }]}>
                <div>
                    <Box variant="h1">Mission of Sustainable AI team</Box>
                    <Box variant="p" color="text-body-secondary" margin={{ top: 'xxs', bottom: 's' }}>
                        We are a group of professionals from Data Science and IT fields that are determined to drive integration
                        of sustainability principles into business solutions. We specifically focus on AI aspect of this direction both to
                        transform conventional applications into a smarter ones which are usually more efficient and to make sure that the way AI is used is safe and responsible.
                    </Box>
                    {/*<SpaceBetween size="xs">
                        <div>
                            Inspired by:{' '}
                            <Link variant="primary" href="#" external={true}>
                                United Nations SDGs
                            </Link>
                        </div>
                        <div>Team: Andrey Spiridonov | .... | .... </div>
                    </SpaceBetween>*/}
                </div>

                <Box margin={{ top: 'l' }}>
                    <SpaceBetween size="s">
                        {/*<Button variant="primary" fullWidth={true}>*/}
                        {/*    View purchase options*/}
                        {/*</Button>*/}
                        {/*<Button fullWidth={true}>Try for free</Button>*/}
                    </SpaceBetween>
                </Box>
            </Grid>
        </Box>
    );
}

export { HeaderCustom };